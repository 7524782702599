import React from "react";
import { FaYenSign } from "react-icons/fa";
import { MdOndemandVideo } from "react-icons/md";

import "./../css/Onsale.css";

import umusa1 from "./../images/umusaseiyaku1.jpg";
import umusa2baikyaku from "./../images/umusa2-1baikyaku.jpg";
import umusa2 from "./../images/umusaralabel.jpg";
import hamamoto1 from "./../images/hamamotolabel.jpg";
// import ryugujyomae1 from "./../images/ryugujyomae1.jpg";
// import yamakwagsyoko1 from "./../images/yamakwagsyoko1.jpg";
import nanmamui1 from "./../images/nanmamuilabel.jpg";
import yaga1 from "./../images/yagalabel.jpg";
import untenbaru1 from "./../images/untenbarulabel.jpg";

const Onsale = () => {
  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>販売中物件</span>
                </div>
                <h2 class="section-title">On sale</h2>
              </div>
            </div>
          </div>
          <div class="col-md-12 description text-lead text-left">
            <h4>
              プライベートビーチ、オンザビーチ、クルーザー停留可能
              ラグジュアリー物件多数
            </h4>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* 物件1 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={umusa1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips">ご成約済</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          目の前プライベートビーチ　内海の湾のような地形に美しく穏やかな海が波打つ　別荘・セカンドハウスに
                          {/* </a> */}
                        </h4>
                        {/* <div>
                          <FaYenSign />
                          <span className="en-family">
                            <strong className="en-number">15,000</strong>万円
                          </span>
                        </div> */}
                        <div className="salespoint">
                          <p>
                            <strong>所在/</strong>沖縄県名護市字宇茂佐
                            <br />
                            <strong>敷地/</strong>293.88㎡(88.89坪)
                          </p>
                          {/* <a
                            href="https://www.e-uchina.net/bukken/tochi/t-6341-6240103-0031/detail.html"
                            class="btn btn-accent btn-full btn-rounded"
                          >
                            他サイトで詳細を見る
                          </a> */}
                        </div>
                      </div>
                    </article>
                  </div>

                  {/* 物件3 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={nanmamui1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips-red">
                            <MdOndemandVideo />
                          </div>
                          <div className="chips">海見え　|　饒平名</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          沖縄の松島 絶景 プライベートビーチ 船舶係留可
                          マリンアクティビティ◎
                          {/* </a> */}
                        </h4>
                        <div>
                          <span className="en-family">価格/応相談</span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県名護市字饒平名湧増
                              <br />
                              <strong>敷地/</strong>24,739㎡(7,483.54坪)
                            </p>
                            <a
                              href="https://suumo.jp/tochi/__JJ_JJ010FJ100_arz1090z2bsz1030z2ncz176632553.html"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              他サイトで詳細を見る
                            </a>
                            <a
                              href="/nanmamui"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  {/* 物件4 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={yaga1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips-red">
                            <MdOndemandVideo />
                          </div>
                          <div className="chips">海見え　|　屋我</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          プライベートビーチ 白砂
                          透明度の高い東シナ海に面した絶景 古宇利島一望
                          分割相談可
                          {/* </a> */}
                        </h4>
                        <div>
                          <span className="en-family">価格/応相談</span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県名護市屋我
                              <br />
                              <strong>敷地/</strong>18,740㎡(5,668.85坪)
                            </p>
                            <a
                              href="/yaga"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  {/* 物件5 */}
                  {/* <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        <img src={ryugujyomae1} alt="post" class="post-image" />
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips">販売中土地</div>
                          <div className="chips-red">
                            <MdOndemandVideo />
                          </div>
                        </div>
                        <h4 class="post-title">
                          58号線沿いホテルマハイナ/美ら海水族館続く
                          周辺高稼働率ヴィラ複数新設
                        </h4>
                        <div>
                          <span className="en-family">
                            価格/お問い合わせください。
                          </span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県国頭郡字山川西原
                              <br />
                              <strong>敷地/</strong>5,259.08㎡(1,590.87坪)
                            </p>
                            <a
                              href="/ryuguzyo"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div> */}

                  {/* 物件6 */}
                  {/* <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        <img
                          src={yamakwagsyoko1}
                          alt="post"
                          class="post-image"
                        />
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips">販売中土地</div>
                          <div className="chips-red">
                            <MdOndemandVideo />
                          </div>
                        </div>
                        <h4 class="post-title">
                          オーシャンビュー 2026年電柱地中埋設完了予定
                          マンション/リゾートホテル分割相談可 店舗付きヴィラ13棟
                          想定利回り 12％越
                        </h4>
                        <div>
                          <span className="en-family">
                            価格/お問い合わせください。
                          </span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県国頭郡本部町山川
                              <br />
                              <strong>敷地/</strong>5,325.83㎡(1,617.11坪)
                            </p>
                            <a
                              href="/yamakawags"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div> */}

                  {/* 物件7 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={hamamoto1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips-red">
                            <MdOndemandVideo />
                          </div>
                          <div className="chips">海見え　|　浜元</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          180度アラウンドオーシャンビュー
                          （本部大橋→瀬底大橋→瀬底島→ヒルトンホテル（花火）→
                          水納島→絶景夕日→伊江島→本部げんき村）3棟現在建築中
                          {/* </a> */}
                        </h4>
                        <div>
                          <span className="en-family">価格/応相談</span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県国頭郡本部町字浜元浜元原
                              <br />
                              <strong>敷地/</strong>191.81㎡(58.02坪)
                            </p>
                            <a
                              href="/hamamoto"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  {/* 物件8 */}
                  <div class="col-md-4">
                    <article class="post-item">
                      <figure>
                        {/* <a href="#" class="image-hvr-effect"> */}
                        <img src={untenbaru1} alt="post" class="post-image" />
                        {/* </a> */}
                      </figure>
                      <div class="post-content">
                        <div class="meta-date">
                          <div className="chips-red">
                            <MdOndemandVideo />
                          </div>
                          <div className="chips">海見え　|　運天原</div>
                        </div>
                        <h4 class="post-title">
                          {/* <a href="#"> */}
                          海に面した完全プライベートマリーナ用地 限定1区画
                          {/* </a> */}
                        </h4>
                        <div>
                          <FaYenSign />
                          <span className="en-family">
                            <strong className="en-number">35,000</strong>万円
                          </span>
                        </div>
                        <div className="salespoint">
                          <div>
                            <p>
                              <strong>所在/</strong>
                              沖縄県名護市運天原大池
                              <br />
                              <strong>敷地/</strong>3325.26㎡(1005.26坪)
                            </p>
                            <a
                              href="https://suumo.jp/tochi/__JJ_JJ010FJ100_arz1090z2bsz1030z2ncz176631846.html"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              他サイトで詳細を見る
                            </a>
                            <a
                              href="/Untenbaru"
                              class="btn btn-accent btn-full btn-rounded"
                            >
                              詳細はこちら
                            </a>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Onsale;
