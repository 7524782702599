import React from "react";

import hero1 from "./../images/hero1-2.jpg";
import hero2 from "./../images/hero2-1.jpg";
import hero3 from "./../images/hero3-1.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "./../css/Hero.css";

const Hero = () => {
  return (
    <>
      {/* <section id="intro"> */}
      {/* <HeaderMovie /> */}
      {/* <div class="image-holder ">
          <video
            src={video}
            autoPlay
            loop
            muted
            className="showcase-video"
          ></video>
          <div class="overlay"></div>
        </div>
        <div class="container-lg ">
          <div class="banner-content position-absolute ">
            <h4 class="banner-title light display-2 text-capitalize py-3 lh-sm fontsize ">
              OKINAWA RESORT LIFE
            </h4>
            <span class="light text-uppercase">
              ベルツコーポレーションでは、
              <br />
              沖縄のオーシャンビュー、オーシャンフロントの土地を数多くご提案させていただきます。
            </span>
          </div>
        </div>
      </section> */}
      <section id="intro">
        {/* <HeaderMovie /> */}
        <div class="image-holder "></div>
        {/* スワイパー */}
        {/* <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide className="swiper-sliede">
            <img src={test} alt="banner" className="img-slide" />
          </SwiperSlide>
          <SwiperSlide className="swiper-sliede">
            <img src={test} alt="banner" className="img-slide" />
          </SwiperSlide>
          <SwiperSlide className="swiper-sliede">
            <img src={test} alt="banner" className="img-slide" />
          </SwiperSlide>
        </Swiper> */}
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 500000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={hero1} alt="banner" className="hero_image" />
            <div class="container-lg ">
              <div class="banner-content position-absolute ">
                <h1 class="">
                  {/* <h3 class="banner-title light display-2 text-capitalize py-3 lh-sm fontsize "> */}
                  沖縄、海。
                  <br />
                  その立地に特化して。
                </h1>
                <p className="text_hero">
                  {" "}
                  <br />
                  眺望の優れたオーシャンビュー、
                  <br />
                  オーシャンフロントの物件を数多くご紹介しています。
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={hero2} alt="banner" className="hero_image" />
            <div class="container-lg ">
              <div class="banner-content position-absolute ">
                <h1 class="title_hero">YAGA</h1>
                <h4 class="subtitle_hero">沖縄県北部－屋我</h4>
                <p className="text_hero">
                  目の前が海、0分アクセスのオーシャンフロントなど
                  <br />
                  沖縄の希少価値の高い物件を数多くご用意。
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src={hero3} alt="banner" className="hero_image" />
            <div class="container-lg ">
              <div class="banner-content position-absolute text-box">
                <h1 class="title_hero">YAMAKAWA</h1>
                <h4 class="subtitle_hero">沖縄県北部－山川</h4>
                <p className="text_hero">
                  オーシャンビュー、サンセット、他島美を眺める内海
                  <br />
                  沖縄の特に眺望の優れた物件をご紹介しています。
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
};

export default Hero;
