import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import { FaYenSign } from "react-icons/fa";

import "./../css/Property.css";
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/navigation";
import "swiper/css";
import "./../css/Pages.css";

import yaga1 from "./../images/yaga7.jpg";
import yaga2 from "./../images/yaga2.jpg";
import yaga3 from "./../images/yaga3.jpg";
import yaga4 from "./../images/yaga4.jpg";
import yaga5 from "./../images/yaga5.jpg";

const Yaga = () => {
  const [thumbs, setThumbs] = useState(null);
  const images = [yaga1, yaga2, yaga3, yaga4, yaga5];

  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div class="container">
          {/* <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>販売中物件</span>
                </div>
                <h2 class="section-title">On sale</h2>
              </div>
            </div>
          </div> */}
          {/* 物件情報ここから1 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div>
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        プライベートビーチ 白砂 透明度の高い東シナ海に面した絶景
                        古宇利島一望 分割相談可
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      {/* <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper> */}

                      {/* Swiper2 */}
                      <Swiper
                        className="pages-swiper"
                        loop={true}
                        modules={[Navigation, Thumbs]}
                        thumbs={{
                          swiper: thumbs && !thumbs.destroyed ? thumbs : null,
                        }}
                        navigation
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} className="img-slide3" />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <Swiper
                        className="pages-swiper Swiper1"
                        slidesPerView={5}
                        onSwiper={setThumbs}
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} />
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      <iframe
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/-ucnup40bUs?si=yAoUBcvfV1sTIDic"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>

                      <div className="comment-box">
                        <p className="">
                          {/* 庭からそのまま海へアプローチ！ジェットスキーも釣りもご自分の”庭”からできます！
                          名護の繁華街までも近く、周辺の施設も充実しております。 */}
                        </p>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="box-text">
                          <p>お問い合わせください。</p>
                        </div>
                        <div />
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県名護市屋我</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から車で約1時間30分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>18,740㎡(5,668.85坪)</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>地目</p>
                          </div>
                          <div className="box-text">
                            <p>宅地</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>用途地域</p>
                          </div>
                          <div className="box-text">
                            <p>未指定</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>建蔽率</p>
                          </div>
                          <div className="box-text">
                            <p>60%(他制限あり)</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>容積率</p>
                          </div>
                          <div className="box-text">
                            <p>200%(他制限あり)</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>接道</p>
                          </div>
                          <div className="box-text">
                            <p>北西側2.9M　北東側3.1M</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>設備</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>現況</p>
                          </div>
                          <div className="box-text">
                            <p>古屋有(更地渡し予定)</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>取引態様</p>
                          </div>
                          <div className="box-text">
                            <p>仲介</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <div class="iframe-box">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3565.6971573050087!2d128.02952217543074!3d26.658177176799832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDM5JzI5LjQiTiAxMjjCsDAxJzU1LjYiRQ!5e0!3m2!1sja!2sjp!4v1737254433264!5m2!1sja!2sjp"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                  {/* <video
                    src={video}
                    autoPlay
                    loop
                    muted
                    className="showcase-video"
                  ></video> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Yaga;
