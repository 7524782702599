import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import { FaYenSign } from "react-icons/fa";

import "./../css/Property.css";
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/navigation";
import "swiper/css";
import "./../css/Pages.css";

import hamamoto1 from "./../images/hamamoto1.jpg";
import hamamoto2 from "./../images/hamamoto2.jpg";
import hamamoto3 from "./../images/hamamoto3.jpg";
import hamamoto4 from "./../images/hamamoto4.jpg";
import hamamoto5 from "./../images/hamamoto5.jpg";
// import video from "./../images/hamamoto_movie.mp4";

const Hamamoto = () => {
  const [thumbs, setThumbs] = useState(null);
  const images = [hamamoto1, hamamoto2, hamamoto3, hamamoto4, hamamoto5];

  return (
    <>
      <section id="on-sale" class="scrollspy-section padding-large">
        <div class="container">
          {/* <div class="row">
            <div class="col">
              <div class="section-header">
                <div class="title">
                  <span>販売中物件</span>
                </div>
                <h2 class="section-title">On sale</h2>
              </div>
            </div>
          </div> */}
          {/* 物件情報ここから1 */}
          <div class="row">
            <div class="col-md-12">
              <div class="post-grid">
                <div class="row">
                  {/* <div class="col-md-3"> */}
                  <article class="post-item">
                    {/* <figure> */}
                    {/* <a href="#" class="image-hvr-effect"> */}
                    {/* <img src={umusa} alt="post" class="post-image" /> */}
                    {/* </a> */}
                    {/* </figure> */}
                    <div class="post-content">
                      <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div>
                      <h3 class="post-title">
                        {/* <a href="#"> */}
                        180度アラウンドオーシャンビュー
                        （本部大橋→瀬底大橋→瀬底島→ヒルトンホテル（花火）→
                        水納島→絶景夕日→伊江島→本部げんき村）3棟現在建築中
                        {/* </a> */}
                      </h3>

                      {/* スワイパー */}
                      {/* <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa1}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                        <SwiperSlide className="swiper-sliede">
                          <img
                            src={umusa2}
                            alt="banner"
                            className="img-slide"
                          />
                        </SwiperSlide>
                      </Swiper> */}

                      {/* Swiper2 */}
                      <Swiper
                        className="pages-swiper"
                        loop={true}
                        modules={[Navigation, Thumbs]}
                        thumbs={{
                          swiper: thumbs && !thumbs.destroyed ? thumbs : null,
                        }}
                        navigation
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} className="img-slide3" />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <Swiper
                        className="pages-swiper Swiper1"
                        slidesPerView={5}
                        onSwiper={setThumbs}
                      >
                        {images.map((src, idx) => (
                          <SwiperSlide key={idx}>
                            <img src={src} />
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      {/* <div class="meta-date">
                        <div className="chips">販売中土地</div>
                      </div> */}
                      <div class="tag-box">
                        <div class="box">
                          <div class="centered-text">別荘</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">ヴィラ</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンビュー</div>
                        </div>
                        <div class="box">
                          <div class="centered-text">オーシャンフロント</div>
                        </div>
                      </div>

                      {/* <video
                        src={video}
                        muted
                        autoPlay
                        playsInline
                        loop
                        className="showcase-video"
                      ></video> */}
                      <iframe
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/E6QOokdlwzs?si=g4qxaq9YK3TjPZmz"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>

                      <div className="comment-box">
                        <p className="">
                          {/* 庭からそのまま海へアプローチ！ジェットスキーも釣りもご自分の”庭”からできます！
                          名護の繁華街までも近く、周辺の施設も充実しております。 */}
                        </p>
                      </div>

                      <div className="price-box">
                        <div className="price-title">
                          <p>価格</p>
                        </div>
                        <div className="box-text">
                          <p>金額についてはご相談ください。</p>
                        </div>
                        <div />
                      </div>

                      <div className="all-box">
                        <div className="one-box">
                          <div className="box-title">
                            <p>所在地</p>
                          </div>
                          <div className="box-text">
                            <p>沖縄県国頭郡本部町字浜元浜元原</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>交通</p>
                          </div>
                          <div className="box-text">
                            <p>那覇空港から約1時間30分</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地権利</p>
                          </div>
                          <div className="box-text">
                            <p>所有権</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>土地面積</p>
                          </div>
                          <div className="box-text">
                            <p>
                              A 191.81㎡(58.02) B 171,78㎡(51.96坪) C
                              138.00㎡(41坪)
                            </p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>地目</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>用途地域</p>
                          </div>
                          <div className="box-text">
                            <p>無指定</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>建蔽率</p>
                          </div>
                          <div className="box-text">
                            <p>60%</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>容積率</p>
                          </div>
                          <div className="box-text">
                            <p>200%</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>接道</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>設備</p>
                          </div>
                          <div className="box-text">
                            <p>-</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>現況</p>
                          </div>
                          <div className="box-text">
                            <p>更地</p>
                          </div>
                        </div>
                        <div className="one-box">
                          <div className="box-title">
                            <p>取引態様</p>
                          </div>
                          <div className="box-text">
                            <p>売主</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <div class="iframe-box">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3565.2537393495377!2d127.88683537543113!3d26.672366176791254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDQwJzIwLjUiTiAxMjfCsDUzJzIxLjkiRQ!5e0!3m2!1sja!2sjp!4v1737254896988!5m2!1sja!2sjp"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hamamoto;
