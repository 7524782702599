import React from "react";

import "./../css/News.css";

const News = () => {
  return (
    <>
      <section id="news" class="scrollspy-section padding-xlarge ">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="testimonial-block">
                <div class="section-header">
                  <div class="title">
                    <span>更新情報</span>
                  </div>
                  <h2 class="section-title">News</h2>
                </div>

                <div class="col-md-12 description text-lead text-left">
                  <h4>随時、新着物件を掲載予定です。</h4>
                  {/* <p className="text">
                    不動産の買取、仲介、企画設計、からコンサルティングまで、
                    <br />
                    全てのお客様を笑顔にする事業活動を行っております。
                  </p> */}
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="col-md-12 description text-lead text-left">
                {/* <h4>お気軽にお問い合わせください。</h4> */}
                <ul class="news-list">
                  <li>
                    <span>2025-03-09</span>
                    <p>物件情報　動画を更新しました</p>
                  </li>
                  <li>
                    <span>2025-02-20</span>
                    <p>宇茂佐　A土地　B土地(1区画)を御成約いただきました</p>
                  </li>
                  <li>
                    <span>2024-12-15</span>
                    <p>宇茂佐/饒平名/屋我/浜元/運天原 物件情報を掲載しました</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
